<template>
  <div class="dialog">
    <div class="dialog-header">
      <p class="dialog-title mb-0">CREATE ACCOUNT</p>
    </div>

    <div class="dialog-content"
         style="overflow-y: auto;"
         v-bind:style="{height: contentHeightOverride}">
      <b-container class="mt-3" fluid>
        <b-form class="create-enterprise-form" @submit="createAccount">
          <b-form-group label="Email:"
                        :label-cols="3"
                        horizontal>
            <b-form-input id="email"
                          type="email"
                          placeholder="Enter Email"
                          autocomplete="off"
                          v-model.trim="$v.form.email.$model"
                          :state="!$v.form.email.$error"
                          @input="$v.form.email.$touch()"
            ></b-form-input>
            <b-form-invalid-feedback id="formEmail">
              <span v-if="!$v.form.email.required">
                Email is required.
              </span>
              <span v-if="!$v.form.email.email">
                Please provide a valid email.
              </span>
              <span v-if="!$v.form.email.uniqueEmail &&
                      $v.form.email.email && $v.form.email.required">
                Email is already registered.
              </span>
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group label="Password:"
                        :label-cols="3"
                        horizontal>
            <b-form-input id="password"
                          type="password"
                          placeholder="Enter Password"
                          autocomplete="off"
                          v-model.trim="$v.form.password.$model"
                          :state="!$v.form.password.$error"
                          @input="$v.form.password.$touch()"
            ></b-form-input>
            <b-form-invalid-feedback id="formPassword">
              <span v-if="!$v.form.password.required">
                Password is required.
              </span>
              <span v-if="!$v.form.password.minLength">
                Minimum length must be
                {{ $v.form.password.$params.minLength.min }} characters.
              </span>
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group label="Confirm Password:"
                        :label-cols="3"
                        horizontal>
            <b-form-input id="confirmPassword"
                          type="password"
                          placeholder="Re-enter Password"
                          autocomplete="off"
                          v-model.trim="$v.confirmPassword.$model"
                          :state="!$v.confirmPassword.$error"
                          @input="$v.confirmPassword.$touch()"
            ></b-form-input>
            <b-form-invalid-feedback id="formConfirmPassword">
              <span v-if="!$v.confirmPassword.required">
                Confirm Password is required.
              </span>
              <span v-if="!$v.confirmPassword.sameAsPassword &&
                      $v.confirmPassword.required">
                Passwords must be identical.
              </span>
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group label="School:"
                        :label-cols="3"
                        horizontal>
            <school-widget @changed="setSchool"
                           :allowAdd="true"
                           ref="schoolWidget">
            </school-widget>
          </b-form-group>

          <h3>Subscription</h3>

          <b-form-group label="User Limit:"
                        :label-cols="3"
                        horizontal>
            <b-form-input id="userLimit"
                          type="number"
                          placeholder="Enter User Limit"
                          autocomplete="off"
                          v-model.trim="$v.form.userLimit.$model"
                          :state="!$v.form.userLimit.$error"
                          @input="$v.form.userLimit.$touch()"
            ></b-form-input>
            <b-form-invalid-feedback id="formUserLimit">
              <span v-if="!$v.form.userLimit.required">
                User Limit is required.
              </span>
              <span v-if="!$v.form.userLimit.numeric &&
                      $v.form.userLimit.required">
                User Limit is must be number.
              </span>
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group label="Price (USD):"
                        :label-cols="3"
                        horizontal>
            <b-form-input id="price"
                          type="number"
                          step="any"
                          placeholder="Enter Price"
                          autocomplete="off"
                          v-model.trim="$v.form.price.$model"
                          :state="!$v.form.price.$error"
                          @input="$v.form.price.$touch()"
            ></b-form-input>
            <b-form-invalid-feedback id="formPrice">
              <span v-if="!$v.form.price.required">
                Price is required.
              </span>
              <span v-if="!$v.form.price.decimal &&
                      $v.form.price.required">
                Price must be decimal.
              </span>
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group label="Coupon:"
                        :label-cols="3"
                        horizontal>
            <b-input-group class="mb-0">
              <b-form-input size="sm"
                            v-model.trim="$v.form.coupon.$model"
                            placeholder="Enter/Generate Coupon"
                            :state="!$v.form.coupon.$error"
                            @input="$v.form.coupon.$touch()"
              ></b-form-input>
              <b-input-group-append>
                <b-button variant="primary"
                          title="Generate Coupon"
                          @click="generateCoupon">
                  Generate
                </b-button>
              </b-input-group-append>
              <b-form-invalid-feedback id="formCoupon">
                <span v-if="!$v.form.coupon.required">
                  Coupon is required.
                </span>
                <span v-if="!$v.form.coupon.minLength">
                  Coupon min length must be {{ $v.form.coupon.$params
                  .minLength.min }} characters.
                </span>
                <span v-if="!$v.form.coupon.maxLength">
                  Coupon max length must be {{ $v.form.coupon.$params
                  .maxLength.max }} characters
                </span>
                <span v-if="!$v.form.coupon.uniqueCoupon">
                  Coupon is already used.
                </span>
              </b-form-invalid-feedback>
            </b-input-group>
          </b-form-group>

          <b-form-group label="Status:"
                        :label-cols="3"
                        horizontal>
            <b-form-select class="form-control"
                           v-model="form.status"
                           :options="statusOptions"
            ></b-form-select>
          </b-form-group>

          <!-- Buttons -->
          <div class="d-flex justify-content-end">
            <b-button class="text-center"
                      type="submit"
                      :disabled="$v.$invalid"
                      variant="outline-success">
              Submit
            </b-button>
            <b-button class="text-center ml-1 "
                      variant="outline-danger"
                      @click="$emit('close')">
              Cancel
            </b-button>
          </div>
        </b-form>
      </b-container>
    </div>

    <!-- Loading -->
    <loading :active.sync="isLoading"
             :is-full-page="true"
             background-color="#000"
             color="#068bcd"
             :opacity="0.3"
             :width="70"
             :height="70">
    </loading>
  </div>
</template>

<script>
  const Loading = () => import('vue-loading-overlay');
  const SchoolWidget = () => import('@/components/shared/SchoolWidget.vue');

  import _ from 'lodash';
  import 'vue-loading-overlay/dist/vue-loading.css';
  import BreakpointMixin from '@/mixins/BreakpointMixin';
  import { required, minLength, maxLength, email, sameAs, numeric, decimal }
    from 'vuelidate/lib/validators';

  export default {
    name   : 'CreateEnterprise',
    mixins : [
      BreakpointMixin,
    ],
    data() {
      return {
        isLoading : false,
        form      : {
          email     : null,
          password  : null,
          schoolId  : null,
          coupon    : null,
          userLimit : null,
          price     : null,
          status    : 'for payment',
        },
        confirmPassword : null,
        uniqueEmail     : true,
        uniqueCoupon    : true,
        statusOptions   : [ {
          value : 'for payment',
          text  : 'For Payment',
        }, {
          value : 'active',
          text  : 'Active',
        } ],
      }
    },
    components : {
      Loading,
      SchoolWidget,
    },
    watch : {
      'form.email'() {
        this.uniqueEmail = true;
        this.checkEmail();
      },
      'form.coupon'(val) {
        this.uniqueCoupon = true;
        if (val) {
          this.form.coupon = val.toUpperCase();
          this.checkCoupon();
        }
      },
    },
    methods : {

      /**
       * Create an Enterprise Account
       */
      createAccount(e) {
        e.preventDefault();
        this.isLoading = true;
        this.$http.post('api/enterprise/account', this.form)
          .then(() => {
            this.$notify({
              group : 'notif',
              type  : 'success',
              title : 'Success',
              text  : 'Created enterprise account successfully!',
            });
            this.$events.fire('reload');
            this.$emit('close');
          }).catch(() => {
            this.$notify({
              group : 'notif',
              type  : 'error',
              title : 'Failed',
              text  : 'Oops! Something went wrong!',
            });
          }).finally(() => {
            this.isLoading = false;
          });
      },

      /**
       * Check if Email is already registered
       */
      // eslint-disable-next-line no-undef
      checkEmail : _.debounce(function () {
        this.$http.get('api/user/email', {
          params : {
            email : this.form.email,
          },
        }).then(response => {
          // swapped value since api returns true
          //  if email exists hence email is not unique
          this.uniqueEmail = (response.data) ? false : true;
        }).catch(() => {});
      }, 500),

      /**
       * Check if Coupon is unique
       */
      checkCoupon : _.debounce(function() {
        this.$http.get('api/enterprise/checkCoupon', {
          params : {
            coupon : this.form.coupon,
          },
        }).then(response => {
          this.uniqueCoupon = (response.data) ? false : true;
        }).catch(() => {});
      }, 500),

      /**
       * Generate Coupon
       */
      generateCoupon() {
        this.$http.get('api/enterprise/generateCoupon')
          .then(response => {
            this.form.coupon = response.data.coupon;
          })
          .catch(() => {
            this.$notify({
              group : 'notif',
              type  : 'error',
              title : 'Failed',
              text  : 'Oops Something Went Wrong!. Please try again later.',
            });
          });
      },

      /**
       * Set School
       * @param data
       */
      setSchool(data) {
        this.form.schoolId = data;
      },
    },
    validations() {
      return {
        form : {
          email : {
            required,
            email,
            uniqueEmail() {
              return this.uniqueEmail;
            },
          },
          password  : { required, minLength : minLength(5) },
          schoolId  : { required },
          userLimit : { required, numeric },
          price     : { required, decimal },
          coupon    : {
            required,
            minLength : minLength(6),
            maxLength : maxLength(10),
            uniqueCoupon() {
              return this.uniqueCoupon;
            },
          },
          status : { required },
        },
        confirmPassword : {
          required,
          sameAsPassword : sameAs(function() {
            return this.form.password;
          }),
        },
      }
    },
  }
</script>

<style lang="scss" scoped>
  @import "../../../assets/scss/components/admin/enterprise/create-enterprise";
</style>